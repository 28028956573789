import { memo } from "react"

import { Text, cn } from "@spatialsys/web/ui"

type LiveBadgeProps = {
  activeUserCount: number
  className?: string
  isLive?: boolean
}

export const LiveBadge = memo(function LiveBadge(props: LiveBadgeProps) {
  const { activeUserCount, className, isLive } = props

  if (activeUserCount === 0) {
    return null
  }

  return (
    <div
      className={cn(
        "flex h-5 w-fit items-center gap-1.5 rounded-md bg-black/60 px-2 text-[10px] text-white xs:text-xs md:h-6 md:rounded-lg",
        className
      )}
    >
      {isLive && <Text className="font-bold text-red [font-size:inherit]">LIVE</Text>}
      <div className={cn("h-1.5 w-1.5 rounded-full xs:h-2 xs:w-2", isLive ? "bg-red" : "bg-green")} />
      <Text className="font-bold !leading-none [font-size:inherit]">{activeUserCount} online</Text>
    </div>
  )
})
