import { memo } from "react"

import { ReactComponent as PlayButtonArrowIcon } from "@spatialsys/assets/icons/play-button-arrow.svg"
import { Button, cn } from "@spatialsys/web/ui"

import classes from "./play-cta.module.scss"

type PlayCtaProps = {
  disableAnimation?: boolean
  onClick: () => void
  isFreeUgcPlan?: boolean
}

export const PlayCta = memo(function PlayCta(props: PlayCtaProps) {
  const { disableAnimation, onClick, isFreeUgcPlan } = props

  return (
    <Button
      color="white"
      size="lg"
      className={cn(
        classes.container,
        "[--play-cta-font-size:1.5rem] @lg/space-container:[--play-cta-font-size:1.75rem] @2xl/space-container:[--play-cta-font-size:2rem]",
        "h-full font-heading text-[length:--play-cta-font-size] font-black uppercase",
        disableAnimation && "!animate-none"
      )}
      rightIcon={<PlayButtonArrowIcon className="icon ml-[1px] text-black" />}
      onClick={onClick}
    >
      <div className={classes.pulser} />
      {isFreeUgcPlan ? "Play" : "Enter"}
    </Button>
  )
})
