import { clamp } from "lodash"
import { memo, useMemo } from "react"

import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useGetSpaceLimitsQuery } from "@spatialsys/react/query-hooks/spaces-v2/get-space-limits"
import { EnvironmentLoadStatus } from "@spatialsys/unity/app-state"
import { useAppContext } from "@spatialsys/web/app-context"
import { DownloadState, Selectors } from "@spatialsys/web/app-state"
import { Heading } from "@spatialsys/web/ui"
import { isFirefox } from "@spatialsys/web/user-agent"

import { ProgressBar, ProgressBarIndeterminate } from "./progress-bar/progress-bar"
import { ProgressCheckpoint } from "./progress-checkpoint"

/**
 * We assume a compression factor of 3 to estimate the size of the downloaded bytes (reported as uncompressed)
 * vs the total size (reported as compressed).
 *
 * We cannot know the exact compression amount, but choosing a larger number like 3 is better than a smaller number like 2.
 * This is because we would rather have the progress bar jump from 66% to 100%, rather than being stuck at 100% for a long time.
 */
const COMPRESSION_FACTOR = 3

type LoadingPhase =
  | "DOWNLOADING_UNITY"
  | "STARTING_UNITY"
  | "CONNECTING_TO_SPACE"
  | "DOWNLOADING_SPACE"
  | "JOINING_SPACE"
  | "COMPLETE"

export const LoadingProgress = memo(function LoadingProgress() {
  const spaceId = useAppContext((context) => context.state.space.id)

  const unityDownloaded = useAppContext((context) => context.state.unity.downloadState === DownloadState.Done)
  const unityDownloadProgress = useAppContext((context) => context.state.unity.initializationProgress)
  const unityAppStateLoaded = useAppContext((context) => context.state.unity.appStateLoaded)
  const packageBundleDownloadedBytes = useAppContext(
    (context) => context.state.unity.appState.environment.downloadProgress.downloadedBytes
  )
  const packageBundleSize = useAppContext(
    (context) => context.state.unity.appState.environment.downloadProgress.contentLength
  )
  const environmentLoadStatus = useAppContext(
    (context) => context.state.unity.appState.environment.loadStatus ?? EnvironmentLoadStatus.Failed
  )
  const initialDataLoadComplete = useAppContext(
    (context) => context.state.unity.appState.roomSession.initialDataLoadComplete
  )
  const isEmbedded = useAppContext((context) => Selectors.getIsEmbedded(context.state))

  const { data: spaceLimits } = useGetSpaceLimitsQuery(spaceId!, {
    enabled: Boolean(spaceId),
  })
  const { data: flags } = useGetFeatureFlagsQuery()
  const hideSpatialBrandingInLoadingSequence = flags?.featureFlags.hideSpatialBrandingInLoadingSequence

  const packageBundleDownloadProgress = useMemo(() => {
    /**
     * We do not need to use a compression factor in Firefox, as it reports the compressed data size.
     *
     * > Note that for compressed requests of unknown total size, loaded might contain the size of the compressed, or decompressed, data, depending on the browser.
     * > As of 2024, it contains the size of the compressed data in Firefox, and the size of the uncompressed data in Chrome.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent/loaded
     */
    const isFf = typeof navigator !== "undefined" ? isFirefox(navigator.userAgent) : false
    const compressionFactor = isFf ? 1 : COMPRESSION_FACTOR
    return clamp(packageBundleSize ? packageBundleDownloadedBytes / (packageBundleSize * compressionFactor) : 0, 0, 1)
  }, [packageBundleSize, packageBundleDownloadedBytes])

  /**
   * Loading phases:
   *
   * 1. Downloading Unity (determinate)
   * 2. Starting Unity (indeterminate)
   * 3. Downloading space (determinate)
   * 4. Joining space (indeterminate)
   *
   * Step 1 is cached on repeat joins. Step 2 only runs once per session.
   * Step 3 is started by the web app as long as step 1 is complete, even if Unity is not loaded yet.
   */
  const loadPhase: LoadingPhase = useMemo(() => {
    if (!unityDownloaded) return "DOWNLOADING_UNITY"
    if (!unityAppStateLoaded) return "STARTING_UNITY"
    if (!initialDataLoadComplete) return "DOWNLOADING_SPACE"
    switch (environmentLoadStatus) {
      case EnvironmentLoadStatus.Loading:
        return "JOINING_SPACE"
      case EnvironmentLoadStatus.Complete:
        return "COMPLETE"
      case EnvironmentLoadStatus.Downloading:
      default:
        return "DOWNLOADING_SPACE"
    }
  }, [unityDownloaded, unityAppStateLoaded, initialDataLoadComplete, environmentLoadStatus])

  const loadStatusText = useMemo(() => {
    let strPrefix = "Downloading environment..."

    switch (loadPhase) {
      case "DOWNLOADING_UNITY":
        return `Downloading core assets... ${(unityDownloadProgress * 100).toFixed()}%`
      case "STARTING_UNITY":
        return hideSpatialBrandingInLoadingSequence &&
          isEmbedded &&
          (spaceLimits?.plan === "BUSINESS" || spaceLimits?.plan === "ENTERPRISE")
          ? `Launching 3D Space...`
          : `Launching Spatial...`
      case "DOWNLOADING_SPACE":
        if (!packageBundleSize) return `Connecting to servers...`
        if (packageBundleDownloadProgress > 0.45) strPrefix = "Loading textures and models..."
        return `${strPrefix} ${(packageBundleDownloadProgress * 100).toFixed(0)}%`
      case "JOINING_SPACE":
      case "COMPLETE":
        return `Rendering the scene...`
    }
  }, [
    loadPhase,
    unityDownloadProgress,
    hideSpatialBrandingInLoadingSequence,
    isEmbedded,
    spaceLimits?.plan,
    packageBundleSize,
    packageBundleDownloadProgress,
  ])

  const progressDownloadingUnity = useMemo(() => {
    if (loadPhase === "DOWNLOADING_UNITY") return unityDownloadProgress
    return 1
  }, [loadPhase, unityDownloadProgress])

  const progressDownloadSpace = useMemo(() => {
    if (loadPhase === "DOWNLOADING_SPACE") return packageBundleDownloadProgress
    if (loadPhase === "JOINING_SPACE" || loadPhase === "COMPLETE") return 1
    return 0
  }, [loadPhase, packageBundleDownloadProgress])

  return (
    <div className="w-2/5 pt-2 sm:min-w-[540px]">
      <div className="grid grid-cols-4 rounded-l-full rounded-r-full bg-white/20">
        <div className="relative grid grid-cols-1 items-center">
          <div className="absolute left-0 z-10 flex h-2 w-2 items-center justify-center rounded-full border-2 border-white/40 bg-transparent p-[1px] text-black shadow-white/40 [&_svg]:data-[load-state=complete]:opacity-100" />
          <ProgressBar
            classNameBar="rounded-r-none"
            classNameContainer="rounded-r-none"
            progress={progressDownloadingUnity}
          />
          <ProgressCheckpoint isComplete={loadPhase !== "DOWNLOADING_UNITY"} />
        </div>

        <div className="relative grid grid-cols-1 items-center">
          <ProgressBarIndeterminate
            classNameBar="rounded-none left-[2px]"
            classNameContainer="rounded-none"
            isActive={loadPhase === "STARTING_UNITY"}
            isComplete={loadPhase !== "DOWNLOADING_UNITY" && loadPhase !== "STARTING_UNITY"}
          />
          <ProgressCheckpoint isComplete={!(loadPhase === "DOWNLOADING_UNITY" || loadPhase === "STARTING_UNITY")} />
        </div>

        <div className="relative grid grid-cols-1 items-center">
          <ProgressBar
            classNameBar="rounded-none left-[2px]"
            classNameContainer="rounded-none"
            progress={progressDownloadSpace}
          />
          <ProgressCheckpoint
            isComplete={
              !(
                loadPhase === "DOWNLOADING_UNITY" ||
                loadPhase === "STARTING_UNITY" ||
                loadPhase === "DOWNLOADING_SPACE"
              )
            }
          />
        </div>

        <div className="relative grid grid-cols-1 items-center">
          <ProgressBarIndeterminate
            classNameBar="rounded-l-none left-[2px]"
            classNameContainer="rounded-l-none"
            isActive={loadPhase === "JOINING_SPACE"}
            isComplete={loadPhase === "COMPLETE"}
          />
          <div className="absolute right-0 z-10 flex h-2 w-2 items-center justify-center rounded-full border-2 border-white/40 bg-transparent p-[1px] text-black shadow-white/40 [&_svg]:data-[load-state=complete]:opacity-100" />
        </div>
      </div>

      <Heading className="mt-4" asChild size="h4" textAlign="center">
        <p>{loadStatusText}</p>
      </Heading>
    </div>
  )
})
