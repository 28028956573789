import { memo, useMemo } from "react"

import { abbreviateNumber } from "@spatialsys/js/util/format-numbers"
import { cn } from "@spatialsys/web/ui"

type ViewCountProps = {
  className?: string
  numViews: number
}

/**
 * View count for a space.
 * Displays the number of views (abbreviated), followed by the word view(s).
 * The font size defaults to inherit
 */
export const ViewCount = memo(function ViewCount(props: ViewCountProps) {
  const { className, numViews } = props

  const visitCountText = useMemo(() => {
    const num = numViews === 0 ? "-" : abbreviateNumber(numViews, 1)
    return `${num} ${numViews === 1 ? "view" : "views"}`
  }, [numViews])

  return <span className={cn("inline text-[length:inherit] text-[inherit]", className)}>{visitCountText}</span>
})
