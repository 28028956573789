import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import { CSSTransition } from "react-transition-group"

import { ReactComponent as SpatialLogo } from "@spatialsys/assets/icons/logos/spatial.svg"
import { TrackedComponentByMount, TrackedComponents } from "@spatialsys/react/analytics"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useGetSpacePreviewQuery } from "@spatialsys/react/query-hooks/spaces"
import { useGetSpaceLimitsQuery } from "@spatialsys/react/query-hooks/spaces-v2/get-space-limits"
import { UnityMessages } from "@spatialsys/unity/bridge"
import { formatSpacePath } from "@spatialsys/url-utils"
import { useAppContext } from "@spatialsys/web/app-context"
import { Selectors } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"
import { CollapsableText } from "@spatialsys/web/core/js/components/collapsable-text/collapsable-text"
import { LoveCount } from "@spatialsys/web/core/js/components/social-signals/love-count"
import { ViewCount } from "@spatialsys/web/core/js/components/social-signals/view-count"
import { LiveBadge } from "@spatialsys/web/core/js/components/space-badges/live-badge"
import { Button, CenteredLoader, Heading, cn } from "@spatialsys/web/ui"

import { LoadingMediaPreview } from "./loading-media-preview"
import { LoadingProgress } from "./loading-progress"
import { PlayCta } from "./play-cta"

const ANIMATION_LENGTH = 1000

type LoadingSplashProps = {
  showMetadata?: boolean
}

/**
 * The loading splash screen, shown while the Unity client app is starting and the space is loading.
 * The specific logic is determined by the `getShowLoadingScreen` selector function.
 */
export const LoadingSplash = ({ showMetadata }: LoadingSplashProps) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const mediaPreviewRef = useRef<HTMLDivElement>(null)
  const loadingSplashRef = useRef<HTMLDivElement>(null)
  const [loadingSplashComplete, setLoadingSplashComplete] = useBoolean()

  const actions = useAppContext((context) => context.actions)
  const spaceId = useAppContext((context) => context.state.space.id)
  const isSpaceLoading = useAppContext((context) => Selectors.getIsSpaceLoading(context.state))
  // When `isShowing` gets toggled to false, the component will fade/zoom out, then unmount.
  const isShowing = useAppContext((context) => Selectors.getShowLoadingScreen(context.state))
  const isReadyToJoin = useAppContext((context) => context.state.canvas.isReadyToJoin)
  const isEmbeddedOnNonPartner = useAppContext((context) => Selectors.getIsEmbeddedOnNonPartner(context.state))
  const isEmbedded = useAppContext((context) => Selectors.getIsEmbedded(context.state))
  const autoplay = useAppContext((context) => context.state.canvas.autoplay)

  const featureFlagsQuery = useGetFeatureFlagsQuery()
  // Space preview is fetched during SSR, so it's usually available at this point.
  // Avoid refetching, an error is usually due to not having access
  const { data: spaceData } = useGetSpacePreviewQuery(spaceId!, {
    retry: false,
    enabled: Boolean(spaceId),
  })

  const { data: spaceLimits } = useGetSpaceLimitsQuery(spaceId!, {
    enabled: Boolean(spaceId && isEmbeddedOnNonPartner),
  })

  const isFreeUgcPlan = spaceLimits?.plan === "FREE_UGC"

  const isBusinessOrEnterpriseSpace = spaceLimits?.plan === "BUSINESS" || spaceLimits?.plan === "ENTERPRISE"

  const shouldHideNonB2BFeatures =
    isBusinessOrEnterpriseSpace && featureFlagsQuery.data?.featureFlags.hideSpatialBrandingInLoadingSequence
  const space = spaceData?.space
  const creator = spaceData?.creator
  const showLoadingSplashWithoutPreview = isShowing && !isSpaceLoading

  const syncLoveState = useCallback(() => {
    if (space?.id === undefined || space?.liked === undefined) return
    // Sync love state with Unity when loading screen fades
    // Set skipSapiCall to true to avoid making a duplicate API call
    UnityMessages.setSpaceLoved(space.id, space.liked, true)
  }, [space?.id, space?.liked])

  // This state is used to keep the play button displayed after it's clicked instead of showing the progress bar.
  // This is desirable if it's clicked after the space is done loading
  // If we are embedded on a non-partner site we always show the button; this functionality is not behind a feature flag.
  // If we are embedded and autoplay is true, always hide the play button even if embedded on non-partner.
  const [showPlayButtonLocal, setShowPlayButtonLocal] = useBoolean(
    !isReadyToJoin && !autoplay && (featureFlagsQuery.data?.featureFlags.requiresPlayClick || isEmbeddedOnNonPartner)
  )

  const onClickPlay = useCallback(() => {
    actions.setIsReadyToJoinSpace(true)

    // if embedded but NOT embedded on partner, Unity will not download and start until the user clicks play
    // to prevent pre-loading from crashing slower machines.
    if (isEmbeddedOnNonPartner) actions.startUnity()

    // If space is loading, hide the play CTA. otherwise, keep it visible, to avoid flash of progress bar
    if (isSpaceLoading) {
      setShowPlayButtonLocal.setFalse()
    }
  }, [actions, isSpaceLoading, setShowPlayButtonLocal, isEmbeddedOnNonPartner])

  /**
   * Sync the play button state when feature flag updates. The cases are as follows:
   * 1. Not embedded: if ff becomes true, show play CTA.
   * 2. Embedded on partner: if ff becomes true, still don't show play CTA, i.e !(isEmbedded && !isEmbeddedOnNonPartner) is false.
   *    autoplay does not have any effect for partner embeds as they will always hide play CTA.
   * 3. Embedded on non-partner, autoplay true: if ff becomes true, do not show play CTA.
   * 4. Embedded on non-partner, autoplay false: if ff becomes true, show play CTA.
   */
  useEffect(() => {
    if (
      !isReadyToJoin &&
      featureFlagsQuery.data?.featureFlags.requiresPlayClick &&
      !autoplay &&
      !(isEmbedded && !isEmbeddedOnNonPartner)
    ) {
      setShowPlayButtonLocal.setTrue()
    }
  }, [
    isReadyToJoin,
    featureFlagsQuery.data?.featureFlags.requiresPlayClick,
    setShowPlayButtonLocal,
    autoplay,
    isEmbedded,
    isEmbeddedOnNonPartner,
  ])

  const showPlayButton = useMemo(() => {
    // Never show the play button in sandbox space
    if (spaceData?.space.sandbox) return false

    return (
      showPlayButtonLocal ||
      (!isReadyToJoin &&
        !autoplay &&
        (featureFlagsQuery.data?.featureFlags.requiresPlayClick || isEmbeddedOnNonPartner))
    )
  }, [
    spaceData?.space.sandbox,
    showPlayButtonLocal,
    isReadyToJoin,
    isEmbeddedOnNonPartner,
    autoplay,
    featureFlagsQuery.data?.featureFlags.requiresPlayClick,
  ])

  /**
   * Adds fade-in animation when branded loading splash plays so it looks smoother.
   * This is only for when the play CTA appears, which is when the space is embedded
   * on a non-partner and autoplay is false.
   */
  const loadingSplashEnterActiveClass =
    isEmbeddedOnNonPartner && !autoplay ? "transition-opacity duration-200 opacity-100 pointer-events-none" : ""

  return (
    <>
      <CSSTransition
        nodeRef={mediaPreviewRef}
        classNames={{
          exitActive: "transition-all scale-110 duration-200 opacity-0 pointer-events-none",
        }}
        mountOnEnter
        in={isSpaceLoading && Boolean(space)}
        timeout={ANIMATION_LENGTH}
        unmountOnExit
      >
        <div
          ref={mediaPreviewRef}
          className="absolute inset-0 flex items-center justify-center overflow-hidden bg-transparent"
        >
          {space && <LoadingMediaPreview space={space} />}
        </div>
      </CSSTransition>
      {/* Transition to handle showing the branded loading splash video. It will only play when space is embedded. */}
      {!shouldHideNonB2BFeatures && (
        <CSSTransition
          nodeRef={loadingSplashRef}
          classNames={{
            enter: "opacity-0",
            enterActive: loadingSplashEnterActiveClass,
            exitActive: "transition-all scale-110 duration-200 opacity-0 pointer-events-none",
          }}
          mountOnEnter
          /**
           * If play CTA is not visible, e.g it was clicked, show the branded loading splash.
           * Omitting Boolean(space) here to avoid flashing when play CTA not present (embedded on partner site or autoplay true).
           * In this case, the branded loading splash will immediately play when an embedded space is loaded.
           */
          in={isEmbedded && !showPlayButtonLocal && !loadingSplashComplete}
          unmountOnExit
          timeout={ANIMATION_LENGTH}
        >
          <div ref={loadingSplashRef} className="absolute inset-0 z-overlay overflow-hidden">
            <video
              className="absolute h-full w-full object-cover object-center"
              onEnded={setLoadingSplashComplete.setTrue}
              muted
              autoPlay
            >
              <source src={`${Config.PUBLIC_ASSETS_BASE_URL}/spatial-splash.mp4`} type="video/mp4" />
              <source src={`${Config.PUBLIC_ASSETS_BASE_URL}/spatial-splash.webm`} type="video/webm" />
            </video>
          </div>
        </CSSTransition>
      )}
      <CSSTransition
        nodeRef={nodeRef}
        classNames={{ exitActive: "transition-all scale-110 duration-200 opacity-0 pointer-events-none" }}
        mountOnEnter
        in={isShowing && Boolean(space)}
        onExit={syncLoveState}
        onExited={syncLoveState}
        timeout={ANIMATION_LENGTH}
        unmountOnExit
      >
        <div
          ref={nodeRef}
          // Loading splash is a sibling of SpaceContainer, so it needs its own container query
          className={cn("absolute inset-0 flex items-center justify-center overflow-hidden @container/loading-splash")}
        >
          {!space ? (
            <CenteredLoader color="auto" variant="fancy" />
          ) : (
            <TrackedComponentByMount id={TrackedComponents.SpaceLoadingSplash}>
              <div
                // Use container query to override CSS vars in supported browsers
                className={cn(
                  "grid h-full w-full grid-cols-1",
                  showLoadingSplashWithoutPreview ? "bg-black/30" : "bg-black/60 backdrop-blur",
                  "[--loading-splash-space-name-font-size:2rem] @sm/loading-splash:[--loading-splash-space-name-font-size:2.5rem] @md/loading-splash:[--loading-splash-space-name-font-size:2.75rem] @lg/loading-splash:[--loading-splash-space-name-font-size:3rem] @xl/loading-splash:[--loading-splash-space-name-font-size:4rem] @2xl/loading-splash:[--loading-splash-space-name-font-size:6rem]",
                  "[--loading-splash-description-font-size:0.875rem] @lg/loading-splash:[--loading-splash-description-font-size:1rem]",
                  "[--loading-splash-gap:1rem] @sm/loading-splash:[--loading-splash-gap:1.5rem] @lg/loading-splash:[--loading-splash-gap:2rem]",
                  "[--play-cta-min-height:3rem] @lg/loading-splash:[--play-cta-min-height:3.75rem] @2xl/loading-splash:[--min-height-font-size:4.5rem]"
                )}
              >
                <div className="absolute z-10 grid w-full max-w-[2200px] grid-cols-1 justify-items-center gap-[--loading-splash-gap] px-2 text-white absolute-center">
                  {spaceData && (
                    <div className={cn("grid w-full grid-cols-1 justify-items-center", space.logo ? "gap-4" : "gap-3")}>
                      <LiveBadge activeUserCount={space.activeUserCount} />
                      {space.logo ? (
                        <img
                          src={space.logo}
                          alt={space.name}
                          loading="eager"
                          // @ts-expect-error fetchpriority type is incorrect: https://github.com/facebook/react/issues/25682 is not yet released
                          fetchpriority="high"
                          className="w-1/3"
                        />
                      ) : (
                        <Heading
                          asChild
                          size="m3"
                          textAlign="center"
                          weight="black"
                          // `length:` tells TW this is a font-size: https://tailwindcss.com/docs/adding-custom-styles#resolving-ambiguities
                          className="line-clamp-5 text-[length:--loading-splash-space-name-font-size]"
                        >
                          <div>{space.name}</div>
                        </Heading>
                      )}
                      {showMetadata && (
                        <MetaData
                          displayName={creator?.displayName}
                          numLoves={space.likeCount}
                          numViews={space.joinCount}
                          description={space.description}
                        />
                      )}
                    </div>
                  )}

                  <div className="flex min-h-[--play-cta-min-height] w-full justify-center">
                    {showPlayButton ? (
                      <PlayCta disableAnimation={isReadyToJoin} onClick={onClickPlay} isFreeUgcPlan={isFreeUgcPlan} />
                    ) : (
                      <LoadingProgress />
                    )}
                  </div>
                </div>
                {isEmbeddedOnNonPartner && !shouldHideNonB2BFeatures && (
                  <Button
                    as="a"
                    className="absolute bottom-3 right-3 grid grid-cols-[auto_auto] gap-0.5 rounded-lg"
                    target="_blank"
                    href={`${formatSpacePath({ id: spaceId!, slug: space.slug, shareId: space.shareID })}`}
                    leftIcon={<SpatialLogo />}
                  >
                    Made with Spatial
                  </Button>
                )}
              </div>
            </TrackedComponentByMount>
          )}
        </div>
      </CSSTransition>
    </>
  )
}

type MetaDataProps = {
  displayName?: string
  numLoves: number
  numViews: number
  description?: string
}

const MetaData = memo(function MetaData({ displayName, numLoves, numViews, description }: MetaDataProps) {
  return (
    <div className="grid w-1/2 gap-3">
      <div className="inline-flex justify-center gap-0.5 text-center text-lg font-semibold">
        {displayName && (
          <>
            <span>{displayName}</span>
            <span className="px-0.5">•</span>
          </>
        )}
        <ViewCount numViews={numViews} />
        <span className="px-0.5">•</span>
        <LoveCount numLoves={numLoves} />
      </div>
      {description && (
        <CollapsableText
          text={description}
          collapsedLines={2}
          disableAnimation
          className="text-center font-body text-[length:--loading-splash-description-font-size]"
          showMoreClassName="text-white"
        />
      )}
    </div>
  )
})
