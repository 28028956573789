import { useState } from "react"

import { ReactComponent as CheckIcon } from "@spatialsys/assets/icons/lucide/check.svg"
import { cn } from "@spatialsys/web/ui"

import classes from "./progress-checkpoint.module.scss"

type ProgressCheckpointProps = {
  isComplete: boolean
}

export function ProgressCheckpoint({ isComplete }: ProgressCheckpointProps) {
  const [isInitiallyComplete] = useState(isComplete)

  return (
    <div
      className={cn(
        "absolute -right-1 z-10 flex h-2 w-2 items-center justify-center rounded-full border-2 border-white/40 bg-transparent p-[1px] text-black shadow-white/40",
        "data-[load-state=complete]:border-none data-[load-state=complete]:border-white data-[load-state=complete]:bg-white [&_svg]:data-[load-state=complete]:opacity-100",
        !isInitiallyComplete && isComplete && classes.animate,
        isComplete && isInitiallyComplete && "scale-[2.25]"
      )}
      data-load-state={isComplete ? "complete" : "loading"}
    >
      <CheckIcon className="size-2 opacity-0 transition-opacity" />
    </div>
  )
}
