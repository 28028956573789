import { memo } from "react"

import { SpaceMetadata } from "@spatialsys/js/sapi/types"

type LoadingMediaPreviewProps = {
  space: SpaceMetadata
}

/**
 * Displays the media preview for a space while it's loading.
 * Priority:
 * 1. Custom Video (16:9)
 * 2. Thumbnail (16:9)
 *
 * The preview is rendered with absolute positioning and 100% width and height.
 */
export const LoadingMediaPreview = memo(function LoadingMediaPreview(props: LoadingMediaPreviewProps) {
  const { space } = props
  const { name, customVideo, thumbnail } = space

  return (
    <div className="absolute inset-0 overflow-hidden">
      {(() => {
        if (customVideo?.webm || customVideo?.mp4) {
          return (
            <video
              poster={customVideo.poster || thumbnail}
              autoPlay
              disablePictureInPicture
              disableRemotePlayback
              loop
              muted
              playsInline
              className="h-full w-full object-cover"
            >
              {customVideo.webm && <source type="video/webm" src={customVideo.webm} />}
              {customVideo.mp4 && <source type="video/mp4" src={customVideo.mp4} />}
            </video>
          )
        }

        return <img className="h-full w-full object-cover" src={thumbnail} alt={`${name}`} />
      })()}
    </div>
  )
})
