import { memo, useMemo } from "react"

import { abbreviateNumber } from "@spatialsys/js/util/format-numbers"
import { cn } from "@spatialsys/web/ui"

type LoveCountProps = {
  className?: string
  numLoves: number
}

/**
 * Love count for a space.
 * Displays the number of likes (abbreviated), followed by the word love(s).
 * The font size defaults to inherit
 */
export const LoveCount = memo(function LoveCount(props: LoveCountProps) {
  const { className, numLoves } = props

  const lovesCountText = useMemo(
    () => `${abbreviateNumber(numLoves, 1)} ${numLoves === 1 ? "love" : "loves"}`,
    [numLoves]
  )

  return <span className={cn("inline text-[length:inherit] text-[inherit]", className)}>{lovesCountText}</span>
})
